import criteria2_1d from "./images/criteria-2.1.jpg";
import criteria2_1r from "./images/criteria-2.1-rsp.jpg";

import logoVertical from "./images/logo_vertical.svg";
import logoApp from "./images/app_icon.svg";

import qr_approved from "./images/qr_approved.png";
import qr_rejected from "./images/qr_rejected.png";
import qr_app from "./images/qr_app.png";

export default [
  {
    criteria: "common",
    data: {
      phone: "+(33)2100-6731",
      phoneMask: "(33) 2100 - 6731",
      appApproved: "https://bancoazteca.onelink.me/F2Vv/4a933f40",
      appRejected: "https://bancoazteca.onelink.me/F2Vv/1de8fcb6",
      appOneLink: "https://bancoazteca.onelink.me/F2Vv/9034b2ee",
    },
  },
  {
    criteria: 2.1,
    data: {
      mobile: {
        img: criteria2_1r,
        h1: "Gracias por confiar en nosotros",
        excerpt1: "Actualmente estamos trabajando en tu solicitud.",
        excerpt2: "Si tienes alguna duda siempre puedes llamarnos:",
      },
      desktop: {
        img: criteria2_1d,
        h1: "Gracias por confiar en nosotros",
        excerpt1: "Actualmente estamos trabajando en tu solicitud.",
        excerpt2: "Si tienes alguna duda siempre puedes llamarnos:",
      },
    },
  },
  {
    criteria: 2.2,
    data: {
      mobile: {
        h1: "Actualmente no será posible aprobar tu solicitud",
        logo: logoVertical,
        qr: qr_app,
        excerpt1: "¡Pero tenemos buenas noticias para ti!",
        excerpt2: "Conoce otras opciones de crédexito con nuestros socios",
        excerpt3: "Te invitamos a llamarnos para conocer otras opciones:",
        excerpt4:
          "También puedes realizar tu solicitud con uno de nuestros socios:",
        excerpt5: "Descarga la app para continuar con el proceso:",
      },
      desktop: {
        h1: "Actualmente no será posible aprobar tu solicitud",
        logo: logoVertical,
        qr: qr_app,
        excerpt1: "¡Pero tenemos buenas noticias para ti!",
        excerpt2: "Conoce otras opciones de crédexito con nuestros socios",
        excerpt3: "Te invitamos a llamarnos para conocer otras opciones:",
        excerpt4:
          "También puedes realizar tu solicitud con uno de nuestros socios:",
        excerpt5:
          "Escanea el código QR y descarga la app para continuar con el proceso desde tu móvil:",
      },
    },
  },
  {
    criteria: 2.3,
    data: {
      mobile: {
        h1: "Actualmente no será posible aprobar tu solicitud",
        logo: logoApp,
        qr: qr_app,
        excerpt1: "¡Pero tenemos buenas noticias para ti!",
        excerpt2: "Conoce otras opciones de crédito con nuestros socios",
        excerpt3: "Te invitamos a llamarnos para conocer otras opciones:",
        excerpt4:
          "También puedes realizar tu solicitud con uno de nuestros socios. Descarga la app para continuar con el proceso:",
      },
      desktop: {
        h1: "Actualmente no será posible aprobar tu solicitud",
        logo: logoVertical,
        qr: qr_app,
        excerpt1: "¡Pero tenemos buenas noticias para ti!",
        excerpt2: "Conoce otras opciones de crédito con nuestros socios",
        excerpt3: "Te invitamos a llamarnos para conocer otras opciones:",
        excerpt4:
          "También puedes realizar tu solicitud con uno de nuestros socios. Descarga la app para continuar con el proceso.",
      },
    },
  },
  {
    criteria: 3.1,
    data: {
      mobile: {
        h1: "Nosotros te decimos que sí",
        h2: "Estás a un paso de obtener tu dinero de inmediato",
        logo: logoVertical,
        excerpt1: "Estás a un paso de obtener tu dinero de inmediato",
        excerpt2: "Llámanos y conoce nuestras opciones de crédito:",
        excerpt3:
          "También puedes realizar tu solicitud con uno de nuestros socios",
        excerpt4: "Descarga la app para continuar con el proceso:",
      },
      desktop: {
        h1: "Nosotros te decimos que sí",
        logo: logoVertical,
        qr: qr_app,
        excerpt1: "Estás a un paso de obtener tu dinero de inmediato",
        excerpt2: "Llámanos y conoce nuestras opciones de crédito",
        excerpt3:
          "También puedes realizar tu solicitud con uno de nuestros socios",
        excerpt4:
          "Escanea el código QR y descarga la appa para continuar con el proceso desde tu móvil",
      },
    },
  },
  {
    criteria: 3.2,
    data: {
      mobile: {
        h1: "Nosotros te decimos que sí",
        h2: "Estás a un paso de obtener tu dinero de inmediato",
        logo: logoApp,
        excerpt1: "Estás a un paso de obtener tu dinero de inmediato",
        excerpt2: "Llámanos y conoce nuestras opciones de crédito:",
        excerpt3:
          "También puedes realizar tu solicitud con uno de nuestros socios. Descarga la app para continuar con el proceso:",
      },
    },
  },
];

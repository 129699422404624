import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";

//Components
import { StaticHeader, Footer } from "../Theme";

//scss
import "./static-page.scss";

//criteria
import criteriaArr from "./criteria";

//screens
import Criteria21 from "./screens/Criteria2_1";
import Criteria22 from "./screens/Criteria2_2";
import Criteria23 from "./screens/Criteria2_3";
import Criteria31 from "./screens/Criteria3_1";
import Criteria32 from "./screens/Criteria3_2";

export default function Static({ history }) {
  const { criteria, device } = useParams();

  const matched = _.find(criteriaArr, { criteria: Number(criteria) });
  const noMatch = _.isEmpty(matched) || _.isEmpty(matched.data[device]);

  if (noMatch) {
    history.push("/");
    return null;
  }

  const customHead = {
    h1: matched.data[device].h1 || null,
    h2: matched.data[device].h2 || null,
    h3: matched.data[device].h3 || null,
  };

  const handleBuild = () => {
    switch (Number(criteria)) {
      case 2.1:
        return <Criteria21 criteria={criteria} device={device} />;
      case 2.2:
        return <Criteria22 criteria={criteria} device={device} />;
      case 2.3:
        return <Criteria23 criteria={criteria} device={device} />;
      case 3.1:
        return <Criteria31 criteria={criteria} device={device} />;
      case 3.2:
        return <Criteria32 criteria={criteria} device={device} />;
      default:
        return null;
    }
  };

  return (
    <div className="App static-page">
      <StaticHeader customHead={customHead} />
      <div className="wrapper content-wrapper">{handleBuild()}</div>
      <Footer />
    </div>
  );
}

import React from "react";
import _ from "lodash";

//criteria
import criteriaArr from "../criteria";

import "./layout-qr.scss";

export default function Criteria3_2({ criteria, device }) {
  const matched = _.find(criteriaArr, { criteria: Number(criteria) });
  const common = _.find(criteriaArr, { criteria: "common" });

  const handleQr = () => {
    return (
      <div className="qr-wrapper">
        <img src={matched.data[device].qr} alt="QR" />
      </div>
    );
  };

  const handlePhone = () => {
    switch (device) {
      case "mobile":
        return (
          <a className="btn btn-primary" href={`tel:${common.data.phone}`}>
            Quiero llamar ahora
          </a>
        );
      case "desktop":
        return <div>{common.data.phoneMask}</div>;
      default:
        return null;
    }
  };

  const handleAppDownload = () => {
    return (
      <div className="app">
        <a href={common.data.appOneLink}>
          <img src={matched.data[device].logo} alt="Logo" />
        </a>
      </div>
    );
  };

  const handleLayout = () => {
    switch (device) {
      case "mobile":
        return (
          <React.Fragment>
            <h1>{matched.data[device].h1}</h1>
            <h2>{matched.data[device].excerpt1}</h2>
            <h3>{matched.data[device].excerpt2}</h3>
            <div className="phone">{handlePhone()}</div>
            <h4>{matched.data[device].excerpt3}</h4>
            <div className="grid-wrapper">
              <div className="txt-wrapper">
                <div>{matched.data[device].excerpt4}</div>
                {handleAppDownload()}
              </div>
            </div>
          </React.Fragment>
        );
      case "desktop":
        return (
          <React.Fragment>
            <h2>{matched.data[device].excerpt1}</h2>
            <h3>{matched.data[device].excerpt2}</h3>
            <h4>{matched.data[device].excerpt3}</h4>
            <div className="phone">{handlePhone()}</div>
            <div className="grid-wrapper">
              {handleQr()}
              <div className="txt-wrapper">
                <div>{matched.data[device].excerpt4}</div>
                <div className="img-wrapper">
                  <img src={matched.data[device].logo} alt="Logo" />
                </div>
                <div>{matched.data[device].excerpt5}</div>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return null;
    }
  };

  return (
    <div className={`criteria-2-2 layout-qr ${device}`}>{handleLayout()}</div>
  );
}

import React from "react";
import { inject, observer } from "mobx-react";

//static assets
import siteTxt from "../../assets/siteTxt.json";
import logo from "../../assets/images/logo.svg";

function Header() {
  return (
    <header>
      <div className="wrapper">
        <img src={logo} alt={siteTxt.title} className="logo-main" />
        <div className="excerpt">
          <h1>{siteTxt.h1}</h1>
          <h2>{siteTxt.h2}</h2>
        </div>
      </div>
    </header>
  );
}

export default inject("GlobalStore")(observer(Header));

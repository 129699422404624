import React from "react";
import _ from "lodash";

//criteria
import criteriaArr from "../criteria";

import "./layout-pic.scss";

export default function Criteria2_1({ criteria, device }) {
  const matched = _.find(criteriaArr, { criteria: Number(criteria) });
  const common = _.find(criteriaArr, { criteria: "common" });

  const handlePhone = () => {
    switch (device) {
      case "mobile":
        return (
          <a className="btn btn-primary" href={`tel:${common.data.phone}`}>
            Quiero llamar ahora
          </a>
        );
      case "desktop":
        return <div>{common.data.phoneMask}</div>;
      default:
        return null;
    }
  };

  const handleTxt = () => {
    return (
      <div className="text-container">
        <div className="text-wrapper">
          <div className="excerpt1">
            <span>{matched.data[device].excerpt1}</span>
          </div>
          <div className="excerpt2">{matched.data[device].excerpt2}</div>
          <div className="phone">{handlePhone()}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={`layout-pic ${device}`}>
      <div className="main-img">
        <img src={matched.data[device].img} alt="Logo" />
      </div>
      {handleTxt()}
    </div>
  );
}
